import qs from 'qs';

export default ({ $axios, req, $config, $sentry }) => {
    $axios.defaults.xsrfCookieName = 'csrftoken';
    $axios.defaults.xsrfHeaderName = 'X-CSRFToken';

    const headers = req && req.headers ? Object.assign({}, req.headers) : {};
    $axios.setHeader('X-Forwarded-Host', headers['x-forwarded-host']);
    $axios.setHeader('X-Forwarded-Port', headers['x-forwarded-port']);
    $axios.setHeader('X-Forwarded-Proto', headers['x-forwarded-proto']);
    $axios.setHeader('Access-Control-Allow-Origin', '*');

    $axios.onRequest(config => {
        config.paramsSerializer = params => qs.stringify(Object.fromEntries(Object.entries(params).filter(p => p[1] !== null)), { arrayFormat: 'comma' });

        return config;
    });

    $axios.onError(error => {
        if ($config.SENTRY_DSN_FRONTEND && !$config.IS_DEV) {
            $sentry.captureException(`${error}:
            ${JSON.stringify(error.response)}`);
        }
    });
};
